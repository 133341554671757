const DASHBOARD_THEME = {
    charts: {
        bar: {
            colorStart: '#9E57E0',
            colorEnd: '#6137F1'
        },
        circle: {
            colorStart: '#9E57E0',
            colorEnd: '#6137F1'
        }
    }
}
